<template>
  <div class="article-info">
    <div class="container">
      <div class="article-info__banner">
        <img :src="blog.image" alt="product-image" />
        <p>{{ `дата публикации: ${date}` }}</p>
      </div>
      <h1>{{ blog.title }}</h1>
      <div class="article-info__content" v-html="desc"></div>
      <div class="article-info__tags" v-if="tags.length">
        <span>теги:</span>
        <div class="tag" v-for="(tag, i) in tags" :key="i">
          {{ tag && tag }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PublicAPI from "@/api/PublicAPI";

export default {
  props: ["id"],
  data() {
    return {
      blog: {},
    };
  },
  computed: {
    date() {
      return this.blog.date_added && this.blog.date_added.split(" ")[0];
    },
    desc() {
      return (
        this.blog.description &&
        this.blog.description
          .replace(/&amp;/g, "&")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&quot;/g, "'")
      );
    },
    tags() {
      return this.blog.tags && this.blog.tags.split(", ");
    },
  },
  async created() {
    const blog = await PublicAPI.getBlogData(
      `feed/rest_api/getblog&blog_id=${this.id}`
    );

    this.blog = blog;
  },
};
</script>

<style scoped>
h1 {
  font-size: 64px;
  font-weight: 700;
  line-height: inherit;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
}

.article-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
}

.article-info__banner img {
  width: 100%;
}
.article-info__banner p {
  text-align: end;
  margin-top: 0.5rem;
  margin-bottom: 3rem;
}

.article-info__content {
  margin-top: 2rem;
  line-height: 25px;
}

.article-info__tags {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.article-info__tags .tag {
  padding: 10px 20px;
  border: 1px solid #75b051;
  border-radius: 30px;
  margin: 10px 0 10px 10px;
}
</style>